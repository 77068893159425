@import 'utils';

.faqs {
  @include flex-column;
  gap: spacing(6);

  .title {
    margin-bottom: spacing(6);
  }

  .faqs_item {
    color: var(--color-product-text);
    padding: spacing(6);

    & + .faqs_item {
      border-top: 1px solid var(--color-neutral-400);
    }

    .faqs_item_header {
      display: flex;
      justify-content: space-between;

      svg {
        height: 24px;
        min-width: 24px;
        width: 24px;
      }
    }

    .faqs_item_content {
      padding: spacing(2) spacing(15) 0 0;
    }
  }

  .store_logos {
    @include down(tablet) {
      margin-inline: auto;
    }
  }

  @include up(tablet) {
    gap: spacing(10);
    padding: spacing(15);
  }
  @include up(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .faqs_content {
      width: 66%;
    }
  }
}
