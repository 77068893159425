@mixin flex($direction: row) {
  display: flex;
  flex-direction: $direction;
}

@mixin flex-column {
  @include flex(column);
}

@mixin flex-row {
  @include flex(row);
}

@mixin zebra($color, $stripe: odd) {
  &:nth-child(#{$stripe}) {
    background-color: $color;
  }
}
