@import 'utils';

.title {
  margin-bottom: spacing(4);
}

.apps {
  display: grid;
  grid-template-areas:
    'qr logos'
    'qr logos';

  column-gap: spacing(3);
  width: fit-content;

  .qr_code {
    border: 1px solid var(--color-neutral-400);
    img {
      width: 100px;
      height: 100px;
    }
  }

  .app_store {
    grid-area: logos;
  }

  .play_store {
    grid-area: logos;
    align-self: end;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
