@use 'sass:map';
@use 'sass:string';

//   @include up(phone) {}
//   @include down(tablet) {}
//   @include between(tablet, desktop) {}

$breakpoints: (
        phone: 640px,
        tablet: 768px,
        desktop: 1024px,
) !default;

$next: (
        phone: 'tablet',
        tablet: 'desktop',
) !default;

@function getWidth($breakpointKey) {
  @if map.has-key($breakpoints, $breakpointKey) {
    @return map.get($breakpoints, $breakpointKey);
  }
}

@mixin _media($from: false, $until: false, $and: false, $media-type: all) {
  $min-width: 0;
  $max-width: 0;
  $query: '';

  // FROM: this breakpoint (inclusive)
  @if $from {
    @if type-of($from) == number {
      $min-width: $from;
    } @else {
      $min-width: map.get($breakpoints, $from);
    }
  }

  // UNTIL: this breakpoint (exclusive)
  @if $until {
    @if type-of($until) == number {
      $max-width: $until - 1px;
    } @else {
      $max-width: map.get($breakpoints, $until) - 1px;
    }
  }

  @if $min-width != 0 {
    $query: '#{$query} and (min-width: #{$min-width})';
  }
  @if $max-width != 0 {
    $query: '#{$query} and (max-width: #{$max-width})';
  }
  @if $and {
    $query: '#{$query} and (#{$and})';
  }

  @if $media-type == 'all' and $query != '' {
    $media-type: '';
    $query: string.slice(string.unquote($query), 6);
  }

  @media #{$media-type + $query} {
    @content;
  }
}

@mixin is($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    @include _media($from: $breakpoint, $until: map.get($next, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
          + 'Available breakpoints are: #{map.keys($breakpoints)}.';
  }
}

@mixin up($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    @include _media($from: $breakpoint) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
          + 'Available breakpoints are: #{map.keys($breakpoints)}.';
  }
}

@mixin down($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    @include _media($until: map.get($next, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
          + 'Available breakpoints are: #{map.keys($breakpoints)}.';
  }
}

@mixin between($minBreakpoint, $maxBreakpoint) {
  @if map.has-key($breakpoints, $minBreakpoint) and map.has-key($breakpoints, $maxBreakpoint) {
    @include _media($from: $minBreakpoint, $until: map.get($next, $maxBreakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from: `#{$minBreakpoint}` or `#{$maxBreakpoint}`. '
          + 'Available breakpoints are: #{map.keys($breakpoints)}.';
  }
}
